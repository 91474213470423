<template>
    <div class="workplan-home">

        <h2 class="text-secondary">{{ page.title }}</h2>

        <div class="row mb-5">
            <div class="col-12 col-lg-8 order-1 order-lg-0">

                <div v-html="page.content"></div>

                <div class="border rounded p-5">
                    <h2 class="text-secondary">Jouw werkplan</h2>

                    <div v-if="workplanItems.length > 0">
                        <div class="mb-5"><a href="#" @click.prevent="renderDoc()" class="btn btn-secondary">Download Word-format van jouw werkplan</a></div>

                        <div v-for="workplanItem in workplanItems" :key="workplanItem" class="mb-5 pb-5 border-bottom">
                            <h4 class="h5 text-muted">{{workplanItem.theme}}</h4>
                            <h3 class="h2">{{workplanItem.aspect}}</h3>

                            <ul class="mb-0">
                                <li v-for="action in workplanItem.actions" :key="action">{{action.action}}</li>
                            </ul>
                        </div>

                        <a href="#" @click.prevent="renderDoc()" class="btn btn-secondary">Download Word-format van jouw werkplan</a>
                    </div>

                    <div v-if="initiazedWorkplan && workplanItems.length == 0">
                        Jouw werkplan is op dit moment nog leeg. Ga naar "Acties" en kies bij één of meerdere aspecten met welke actiepunten je aan de slag wilt.
                    </div>

                    <div v-if="!initiazedWorkplan" class="text-center">
                        <i>Jouw werkplan wordt geladen...</i>
                    </div>
                </div>

            </div>
            <div class="col-12 col-lg-5 order-0 order-lg-1">

                <Video :embed="page.videoEmbed" v-if="page.videoEmbed" />

            </div>
        </div>


    </div>
</template>

<script>
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import saveAs from "file-saver";

import Video from '@/components/Video.vue';
import api from '@/data/api'

export default {
    name: 'Home',
    components: {
        Video,
    },
    data() {
        return {
            pageTitle: 'Werkplan onderzoekscultuur',
            page: {},
            initiazedWorkplan: false,
            workplanItems: [],
        }
    },
    mounted() {
        api.fetchPage(5580).then(response => {
            this.page = response;
        });

        this.$store.dispatch('fetchThemes');

        const breadcrumbs = [
            {
                'label': 'Home',
                'path': '/'
            },
            {
                'label': 'Werkplan',
            },
        ];

        this.$store.commit('SET_BREADCRUMBS', {breadcrumbs});
    },
    updated() {
        if (
            !this.initiazedWorkplan &&
            this.themes.length > 0
        ) {
            this.fetchWorkplanItems().then(() => {
                this.initiazedWorkplan = true;
            });
        }
    },
    computed: {
        currentRole() {
            if (this.$store.state.userRole && this.$store.state.roles) {
                return this.$store.getters.getCurrentRole();
            }

            return false;
        },
        themes() {
            return this.$store.state.themes;
        },
    },
    methods: {
        loadFile(url, callback) {
            PizZipUtils.getBinaryContent(url, callback);
        },
        async fetchWorkplanItems() {
            let workplanItems = [];

            for (let themeKey in this.themes) {
                const theme = this.themes[themeKey];

                await api.fetchAspects(theme.id).then(themeAspectsCollection => {
                    // const themeAspects = themeAspectsCollection.filterByView('selected').all();
                    const themeAspects = themeAspectsCollection.all();
                    for (let aspectKey in themeAspects) {
                        if (themeAspects[aspectKey].getChosenActionItems().length > 0) {
                            workplanItems.push({
                                theme: theme.name,
                                aspect: themeAspects[aspectKey].title,
                                actions: themeAspects[aspectKey].getChosenActionItems().map((actionItem) => {
                                    return {'action': actionItem}
                                })
                            });
                        }
                    }
                });
            }

            this.workplanItems = workplanItems;

            return workplanItems;
        },
        replaceErrors(key, value) {
            if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function(
                    error,
                    key
                ) {
                    error[key] = value[key];
                    return error;
                }, {});
            }
            return value;
        },
        renderDoc() {
            this.fetchWorkplanItems().then((workplanItems) => {
                if (workplanItems.length === 0) {
                    alert('Je werkplan is nog leeg.');
                    return;
                }

                this.loadFile("/doc/format_werkplan.docx", (
                    error,
                    content
                ) => {
                    if (error) {
                        throw error;
                    }
                    var zip = new PizZip(content);
                    var doc = new Docxtemplater(zip);
                    doc.setData({
                        "pagebreak": "<w:p><w:br w:type=\"page\" /></w:p>",

                        "date": new Date().toLocaleDateString(),
                        "role": this.currentRole.name,
                        "aspects": workplanItems
                    });
                    try {
                        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                        doc.render();
                    } catch (error) {
                        // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                        console.log(JSON.stringify({
                            error: error
                        }, this.replaceErrors));

                        if (error.properties && error.properties.errors instanceof Array) {
                            const errorMessages = error.properties.errors
                                .map(function(error) {
                                    return error.properties.explanation;
                                })
                                .join("\n");
                            console.log("errorMessages", errorMessages);
                            // errorMessages is a humanly readable message looking like this :
                            // 'The tag beginning with "foobar" is unopened'
                        }
                        throw error;
                    }
                    var out = doc.getZip().generate({
                        type: "blob",
                        mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    }); //Output the document using Data-URI
                    saveAs(out, "werkplan_leeromgeving_onderzoekscultuur.docx");
                });
            });
        }
    },
}
</script>


